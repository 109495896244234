@import "../theme";

.step-container {
  padding-top: 86px;
  justify-content: center !important;
  img {
    height: 130px;
    width: 130px;
    margin-bottom: 40px;
  }
  h2 {
    margin-bottom: 0px !important;
  }
  p {
    color: #696969;
    margin-bottom: 24px;
  }
  .ant-steps {
    padding: 48px 24px 32px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    margin-bottom: 40px;
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      background-color: #00ff6d;
    }
    .ant-steps-item-tail::after {
      height: 1px;
    }
    .ant-steps-icon {
      top: -14px;
      left: -15px;
    }
    .ant-steps-item-content {
      margin-top: 32px;
    }
    .border-item {
      border-radius: 100%;
      width: 34px;
      height: 34px;
      line-height: 32px;
      text-align: center;
      display: block;
      border: 1px solid #00ff6d;
      color: #000;
      font-weight: normal;
      font-size: 16px;
      background-color: #fff;
    }
    .ant-steps-item-wait {
      .border-item {
        border: 1px solid #e0e0e0;
        color: #e0e0e0;
      }
    }
  }
  button {
    margin-bottom: 24px;
  }
  a {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    color: #000;
    text-decoration: underline;
    letter-spacing: 1px;
    font-family: RobotoBold;
  }
}

.event-container {
  .step-container {
    .ant-steps {
      width: 618px;
      .ant-steps-item-description {
        white-space: nowrap;
      }
    }
  }
}

.event-create-container {
  .event-form {
    width: 100%;
    padding: 32px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    margin-bottom: 24px;
    .radio-item {
      display: flex;
      @media @mobile {
        display: block;
      }
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      border: 0;
      padding-left: 0;
      .ant-form-item-label label {
        font-weight: 900;
        font-size: 16px;
        color: #000;
        margin-right: 42px;
        text-transform: capitalize;
        height: auto;
        @media @mobile {
          margin-bottom: 12px;
        }
      }
    }
    .flex {
      & > .ant-form-item {
        flex: 1;
        &:first-child {
          margin-right: 24px;
        }
      }
      @media @mobile {
        flex-direction: column;
        & > .ant-form-item {
          width: 100%;
          margin: 0 0 24px / @pv 0 !important;
        }
      }
    }
    .cover-image {
      .children-upload {
        width: 180px;
        height: 180px;
        display: block;
      }
      .ant-upload-drag-icon {
        margin-bottom: 0 !important;
        margin-top: 20px;
        .cover-image-text {
          display: block;
          width: 100%;
          font-size: 16px;
          text-align: center;
          color: #000;
        }
      }
    }
    .upload-item-wrap {
      .ant-upload-select-picture {
        width: 100%;
      }
    }
    .time-option-wrap {
      margin-bottom: 24px;
      & > span {
        font-weight: bold;
        width: 126px;
        font-size: 16px;
      }
      .ant-select {
        margin-right: 24px;
      }
      .ant-radio-button-wrapper:not(:last-child) {
        margin-right: 24px;
      }
      &.repeat-wrap {
        .ant-radio-button-wrapper:not(:last-child) {
          margin-top: 8px;
          margin-right: 24px;
          margin-bottom: 8px;
        }
      }
      .ant-radio-button-checked {
        background: #dfffed;
        border: 2px solid #00ff6d;
        color: #000;
      }
      .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper {
        border: 0;
      }
      .ant-radio-button-wrapper {
        height: 40px;
        line-height: 40px;
        border: 1px solid #e0e0e0;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        width: 0;
      }
    }
    .org-item {
      input {
        color: #000;
      }
    }
    @media @mobile {
      padding: 16px / @pv;
      .ant-form-item .ant-select-multiple,
      .ant-form-item .ant-select-multiple .ant-select-selector,
      .ant-form-item
        .ant-select.ant-select-multiple
        .ant-select-selection-overflow {
        height: auto !important;
      }
    }
  }
  .btn {
    button {
      margin-left: 24px;
      @media @mobile {
        margin: 0;
        width: auto;
      }
    }
  }
}

.checkbox-select-dropdown {
  .ant-select-item-option {
    &.ant-select-item-option-selected {
      background-color: #fff;
      font-weight: normal;
      .anticon-check-square {
        color: #05a5ff;
        margin-right: 12px;
        font-size: 18px;
      }
    }
    .ant-select-item-option-state {
      display: none !important;
    }
    .ant-select-item-option-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .uncheck {
        display: inline-block;
        width: 17px;
        height: 17px;
        border: 1px solid #696969;
        margin-right: 12px;
      }
    }
    &.select-all-option {
      color: #000;
      cursor: pointer;
      .uncheck {
        display: inline-block;
        width: 17px;
        height: 17px;
        border: 1px solid #696969;
        margin-right: 12px;
      }
      .anticon-check-square,
      .anticon-minus-square {
        color: #05a5ff;
        margin-right: 12px;
        font-size: 18px;
      }
    }
  }
}

.event-list-container {
  width: 100%;
  .range-picker-wrap {
    margin-bottom: 24px;
  }
  .campaign-on-wrap {
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    @media @mobile {
      background: none;
      box-shadow: none;
      border: none;
    }
    h4.title {
      padding: 0 32px;
      margin-bottom: 0 !important;
      height: 58px;
      line-height: 58px !important;
      border-bottom: 1px solid #e0e0e0;
      font-weight: bolder;
      font-family: RobotoBold;
    }
    .campaign-list {
      padding: 32px;
      &:not(:last-child) {
        border-bottom: 1px solid #e0e0e0;
      }
      .flex {
        width: 100%;
      }
      .info-btn {
        height: 100%;
        & > span {
          color: #a0a0a0;
          font-size: 12px;
        }
      }
      h2 {
        line-height: 1 !important;
        margin-bottom: 16px !important;
      }
      img {
        width: 120px;
        height: 120px;
        margin-right: 24px;
        object-fit: cover;
      }
      .campaign-info-wrap {
        height: 120px;
        .info-title {
          height: 100%;
          flex: 0 0 68%;
          & > span {
            font-size: 12px;
            color: #a0a0a0;
            flex: 0 0 248px;
            white-space: normal;
            text-align: right;
          }
        }
        .ant-tag {
          margin-left: 8px;
        }
      }
      ul {
        margin-bottom: 0;
        padding-left: 20px;
        color: #a0a0a0;
        list-style: circle;
        li {
          p {
            display: flex;
            margin-bottom: 4px;
          }
          span {
            display: inline-block;
            width: 120px;
            flex: 0 0 120px;
          }
          i {
            display: inline-block;
            line-height: 24px;
            white-space: normal;
          }
        }
      }
      .btn {
      }
      .ant-dropdown-link {
        width: 48px;
        line-height: 48px;
        height: 48px;
        margin-right: 16px;
        border: 1px solid #000;
        display: inline-block;
        font-size: 26px;
        color: #000;
        text-align: center;
      }
    }
    &.campaign-past-wrap {
    }
  }

  // mobile event item
  .range-picker-wrap {
    @media @mobile {
      width: 100%;
    }
  }
  .event-item-mobile {
    padding: 16px / @pv;
    margin: 16px / @pv 0;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);

    img {
      width: 100%;
      height: 120px / @pv;
      object-fit: contain;
      margin-bottom: 16px / @pv;
    }
    h2 {
      margin-bottom: 0 !important;
    }
    .sub-tip {
      font-size: 12px;
      color: #797f84;
    }
    .info-title {
      ul {
        padding-inline-start: 1em;
        li span {
          color: #797f84;
          min-width: 70px / @pv;
        }
      }
    }
    .ant-dropdown-link {
      width: 48px;
      line-height: 48px;
      height: 48px;
      margin-right: 16px;
      border: 1px solid #000;
      display: inline-block;
      font-size: 26px;
      color: #000;
      text-align: center;
    }
  }
}

.home-event-list-container {
  align-items: center;
  padding-top: 20px;
  & > h1 {
    padding-top: 23px;
    font-size: 48px;
    line-height: 58px;
    width: 100%;
    text-align: center;
  }
  & > p {
    color: #696969;
    width: 100%;
    text-align: center;
  }
  .search-wrap {
    margin-top: 40px;
    padding: 32px;
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    width: 100%;
    .field-form-items {
      width: 25%;
      &:not(:last-child) {
        margin-right: 24px;
      }
      & > input {
        height: 26px;
      }
      .ant-select {
        .ant-select-selector {
          height: 26px;
          padding-left: 0;
        }
      }
      &.clear {
        border: none;
        text-decoration: underline;
        font-weight: bold;
        flex-grow: 0;
        width: 50px;
        padding-left: 0;
        align-self: flex-start;
        position: relative;
        top: 10px;
        cursor: pointer;
      }
    }
  }
  .result-wrap {
    .ant-select-selection-item {
      color: #000;
      font-weight: bold;
    }
  }
  .search-text {
    width: 100%;
    margin-top: 16px;
    font-size: 12px;
    text-align: left;
    color: #333;
  }
  .event-list-wrap {
    width: 100%;
    @media @desktop {
      min-height: 350px;
    }
    .event-list-cell {
      display: flex;
      justify-content: space-between;
      margin-bottom: 56px;

      img {
        flex: 0 0 266px;
        width: 266px;
        height: 266px;
        object-fit: cover;
      }
      .right-item {
        padding: 32px;
        height: 266px;
        border: 1px solid #e0e0e0;
        border-left: 0;
        flex: 0 0 calc(100% - 266px);
        .icon-block-wrap {
          width: 480px;
          p {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            color: #696969;
            &.img_name {
              align-items: center;
            }
            img {
              flex: 0 0 16px;
              width: 16px;
              height: 16px;
              min-height: auto;
              margin-right: 8px;
              border-radius: 16px;
            }
            .icon-block-wrap-address {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
            }
            span&:last-child {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            & > .anticon,
            & > svg {
              margin-right: 16px;
              margin-top: 3px;
            }
          }
        }
      }
      .btn-item {
        button {
          width: 188px;
          &:last-child {
            margin-top: 16px;
            background-color: transparent;
          }
        }
      }
    }
  }
  .loading-wrap {
    margin-bottom: 40px;
  }
  @media @mobile {
    padding: 0 32px / @pv;
    background: #f1f1f6;
    h1 {
      margin-top: 20px / @pv;
      margin-bottom: 16px / @pv;
      font-size: 40px / @pv;
      line-height: 50px / @pv;
      & + p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .search-wrap {
      flex-direction: column;
      padding: 16px / @pv;
      margin-top: 24px / @pv;
      .field-form-items {
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
      .clear-all {
        display: none;
      }
    }
    .result-wrap {
      white-space: nowrap;
      i {
        display: none;
      }
      .ant-select-selector {
      }
    }
    .event-list-wrap {
      .event-list-cell {
        width: 100%;
        margin-right: 0 !important;
        flex-direction: column;
        padding: 0;
        img {
          width: 100%;
          height: auto;
          min-height: 240px / @pv;
        }
        .right-item {
          background-color: #fff;
          flex-direction: column;
          height: auto;
          padding: 24px / @pv;
          border-left: 1px solid #e0e0e0;
          border-top: 0;
          .flex.column.align-start {
            width: 100%;
          }
          .icon-block-wrap {
            width: 100%;
            margin-bottom: 16px / @pv;
            p {
              width: 100%;
              white-space: normal;
              &.img_name {
                align-items: center;
              }
              img {
                flex: 0 0 16px / @pv;
                width: 16px / @pv;
                height: 16px / @pv;
                min-height: auto;
                margin-right: 16px / @pv;
                border-radius: 16px;
              }
              span {
                &:last-child {
                  margin-top: 0;
                  white-space: normal;
                  word-break: break-word;
                }
              }
            }
          }
        }
        .btn-item {
          padding: 32px / @pv 0 0;
          margin: 0 -24px / @pv;
          width: 100%;
          position: relative;
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: -24px / @pv;
            left: -24px / @pv;
            height: 1px;
            background: repeating-linear-gradient(
              90deg,
              transparent,
              transparent 6px,
              #dddfe4 6px,
              #dddfe4 12px
            );
          }
          button {
            width: 100%;
          }
        }
        h3 {
          margin-top: 24px / @pv;
          line-height: 38px / @pv;
          font-size: 24px / @pv;
        }
        p {
          margin-top: 8px / @pv;
          height: auto;
        }
        a {
          margin-top: 24px / @pv;
          font-size: 14px / @pv;
          line-height: 24px / @pv;
        }
      }
    }
  }
}

.home-grant-list-container {
  .grant-list-wrap {
    width: 100%;
    // columns: 2; // 默认列数
    // column-gap: 30px; // 列间距
    display: flex;
    flex-wrap: wrap;
    @media @desktop {
      min-height: 350px;
    }
    .grant-list-cell {
      break-inside: avoid;
      width: calc(50% - 15px);
      &:nth-of-type(even) {
        margin-left: 30px;
      }
      padding: 32px;
      display: flex;
      flex-direction: column;
      border: 1px solid #e0e0e0;
      margin-bottom: 32px;
      position: relative;
      min-height: 580px;
      .img {
        width: 100%;
        height: 200px;
        margin-bottom: 32px;
        object-fit: cover;
        object-position: center;
        background-color: #e0e0e0;
        cursor: pointer;
      }
      .grant-tags {
        position: absolute;
        display: flex;
        padding: 20px;
      }
      .right-item {
        .ant-tag {
          margin-bottom: 8px;
        }
        h2 {
          width: 100%;
          font-size: 26px;
          line-height: 32px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        ul {
          margin-bottom: 8px;
          padding-left: 20px;
          color: #a0a0a0;
          list-style: disc;
          li {
            p {
              display: flex;
              margin-bottom: 4px;
              width: 100%;
            }
            span {
              display: inline-block;
              width: 82px;
              flex: 0 0 82px;
            }
            i {
              display: inline-block;
              line-height: 24px;
              white-space: normal;
              overflow: hidden;
              color: #696969;
            }
          }
        }
        .grant-detail-text {
          line-height: 26px;
          color: #696969;
          margin-bottom: 30px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
        .link {
          font-weight: 900;
          cursor: pointer;
          font-size: 14px;
          line-height: 26px;
          letter-spacing: 0.1em;
          text-decoration-line: underline;
          text-transform: uppercase;
          color: #000000;
          position: absolute;
          left: 32px;
          bottom: 32px;
        }
      }
    }
  }
  @media @mobile {
    .grant-list-wrap {
      display: block;
      columns: 1;
      grid-column-gap: 30px;
      column-gap: 30px;
      .grant-list-cell {
        min-height: auto;
        background-color: #fff;
        width: 100%;
        margin-left: 0 !important;
        padding: 24px / @pv;
        .img {
          margin-bottom: 24px / @pv;
        }
        .right-item {
          h2 {
            font-size: 24px / @pv;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .grant-detail-text {
            font-size: 16px / @pv;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            margin-bottom: 24px / @pv;
          }
          a {
            position: relative;
            left: initial;
            bottom: initial;
          }
        }
      }
    }
  }

  .red-dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: red;
    border-radius: 50%;
    position: relative;
    top: -6px;
    margin-left: 8px;
  }
}
.ant-dropdown-placement-bottomRight {
  .red-dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: red;
    border-radius: 50%;
    position: relative;
    top: 0px;
    margin-left: 8px;
  }
}

.event-info-modal {
  .info-container {
    padding: 0 20px;
    @media @mobile {
      padding: 0;
    }
  }
  .field-form-items {
    width: 100%;
    span {
      text-align: left;
    }
    p {
      margin-bottom: 0;
      height: 26px;
      line-height: 26px;
      width: 100%;

      span {
        font-family: RobotoBold;
        font-weight: 900;
        color: #000;
        cursor: pointer;
        padding-right: 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-decoration: underline;
      }
      @media @desktop {
        i {
          white-space: nowrap;
          flex: 0 0 81%;
          width: 79%;
          margin-right: 1%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }
      }
    }
    @media @mobile {
      p {
        i {
          white-space: nowrap;
          overflow: hidden;
          font-size: 12px / @pv;
          text-overflow: ellipsis;
        }
        span {
          font-size: 12px / @pv;
        }
      }
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    @media @mobile {
      justify-content: flex-end;
      .contact-btn {
        display: none;
      }
      .ant-btn {
        width: auto;
        i {
          display: none;
        }
      }
    }
  }
}
